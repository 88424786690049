import React from "react";
import { Switch, Route } from "react-router-dom";

import Cadastro from "./pages/Main";
import E1304 from "./pages/eventos/biomateriais/1304";
import S1304 from "./pages/eventos/biomateriais/1304/success";

import E1504 from "./pages/eventos/biomateriais/1504";
import S1504 from "./pages/eventos/biomateriais/1504/success";

import E1904 from "./pages/eventos/biomateriais/1904";
import S1904 from "./pages/eventos/biomateriais/1904/success";

import E0206 from "./pages/eventos/biomateriais/0206";
import S0206 from "./pages/eventos/biomateriais/0206/success";

import E0806 from "./pages/eventos/biomateriais/0806";
import S0806 from "./pages/eventos/biomateriais/0806/success";

import E2304 from "./pages/eventos/biomateriais/2304";
import S2304 from "./pages/eventos/biomateriais/2304/success";

import E74400 from "./pages/eventos/biomateriais/74400";
import E76517 from "./pages/eventos/biomateriais/76517";
import E83076 from "./pages/eventos/biomateriais/83076";
import E84977 from "./pages/eventos/biomateriais/84977";
import E86018 from "./pages/eventos/biomateriais/86018";
import E103196 from "./pages/eventos/biomateriais/103196";


import Snc from "./pages/Snclistaeventos";


function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={Cadastro} />

      <Route exact path="/E1304" component={E1304} />
      <Route exact path="/E1304/success" component={S1304} />

      <Route exact path="/E1504" component={E1504} />
      <Route exact path="/E1504/success" component={S1504} />

      <Route exact path="/E1904" component={E1904} />
      <Route exact path="/E1904/success" component={S1904} />

      <Route exact path="/E0806" component={E0806} />
      <Route exact path="/E0806/success" component={S0806} />

      <Route exact path="/E0206" component={E0206} />
      <Route exact path="/E0206/success" component={S0206} />

      <Route exact path="/E2304" component={E2304} />
      <Route exact path="/E2304/success" component={S2304} />

      <Route exact path="/E74400" component={E74400} />
      <Route exact path="/E76517" component={E76517} />
      <Route exact path="/E83076" component={E83076} />
      <Route exact path="/E84977" component={E84977} />
      <Route exact path="/E86018" component={E86018} />
      <Route exact path="/E103196" component={E103196} />

      <Route exact path="/success" component={Snc} />
    </Switch>
  );
};

export default Routes;
