import React, { useState, useEffect } from "react";
import "../../../../styles/main.css";

import { cardioRepList, sncRepList } from "../../../static";
import { useFormik, Field } from "formik";
import * as yup from "yup";

import { addNewUserApi } from "../../../../services/userService";
import { sendEmailApi, getHtmlString } from "../../../../services/emailService";
import { getTokenUserApi } from "../../../../services/tokenService";
import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";

const eventId = 73664;

const validationSchema = yup.object({
	email: yup
		.string("")
		.email("Introduzca una dirección de correo electrónico válida.")
		.required("Correo electronico es requerido."),
});

const E1904 = () => {
	const [percent, setPercent] = useState(0);
	const [loading, setLoading] = useState(false);
	const [expanded, setExpanded] = useState(false);
	const formik = useFormik({
		initialValues: {
			nome: "",
			email: "",
			celular: "",
			cep: "",
			formacao: "",
			client: "",
			contact: "",
			termoPrivacidade: false,
		},
		validationSchema: validationSchema,
		onSubmit: async (values, { resetForm }) => {
			let arrayObj = [];
			let objValue = {};
			let _email = "";
			Object.keys(values).forEach((e) => {
				if (e === "nome") {
					objValue = { id: 194599, value: values[e] };
				}
				if (e === "email") {
					_email = values[e].trim();
					objValue = { id: 194600, value: values[e].trim() };
				}
				if (e === "celular") {
					objValue = { id: 194837, value: values[e] };
				}
				if (e === "cidade") {
					objValue = { id: 197087, value: values[e] };
				}
				if (e === "pais") {
					objValue = { id: 196648, value: values[e] };
				}
				if (e === "cep") {
					objValue = { id: 194839, value: values[e] };
				}
				if (e === "formacao") {
					objValue = { id: 194840, value: values[e] };
				}
				if (e === "client") {
					objValue = { id: 194841, value: values[e] };
				}
				if (e === "contact") {
					objValue = { id: 196385, value: values[e] };
				}
				if (e === "termoPrivacidade") {
					objValue = { id: 194842, value: values[e] };
				}
				arrayObj.push(objValue);
			});

			const grupoId = cardioRepList
				.filter((e) => e.nome === formik.values.representante)
				.map((e) => e.grupoId)[0];
			setPercent(10);

			if (loading) return;
			setLoading(true);

			// Verifica se o usuário já possui cadastro
			var _token = null;
			await getTokenUserApi(_email, eventId)
				.then((res) => {
					_token = res;
				})
				.catch((err) => {
					console.log(err);
				});
			setPercent(20);
			if (_token) {
				window.parent.postMessage("onSubmit_success", "*");
				setLoading(false);
			} else {
				addNewUserApi(arrayObj, false, grupoId, eventId)
					.then(async (res) => {
						resetForm({ values: "" });
						var _textHtml = `
                <img src="https://s3.us-east-1.amazonaws.com/bucket.aws.public/hall_desenvolvimento/cc.png" width="100%"/>
              `;
						setPercent(50);
						await sendEmailApi(
							res.email,
							"Confirmación de registro Straumann e-Symposium LATAM",
							_textHtml
						)
							.then(() => {
								console.log("Email enviado com sucesso");
								window.parent.postMessage("onSubmit_success", "*");
								setLoading(false);
							})
							.catch(() => {
								console.log("Erro ao enviar email");
								setLoading(false);
							});
						setPercent(80);
						/*
            await getTokenUserApi(_email, eventId)
              .then((res) => {
                _token = res;
                window.location.href = `https://hall.inteegra.com.br/72784?token=${_token}`;
              })
              .catch((err) => {
                console.log(err);
              }); */
					})
					.catch((err) => {
						setLoading(false);
						alert(
							"Erro, não foi possível realizar o cadastro: ",
							err.toString()
						);
					});
			}
		},
	});

	// useEffect(() => {
	//   formik.setFieldValue('representante', '');
	// }, [areaSelected]);
	const lista = cardioRepList
		.sort((a, b) => a.nome.localeCompare(b.nome))
		.map((e) => (
			<option key={e.nome} value={e.nome}>
				{e.nome}
			</option>
		));
	// const lista =
	//   areaSelected === 'cardiologia'
	//     ? cardioRepList
	//         .sort((a, b) => a.nome.localeCompare(b.nome))
	//         .map((e) => <option key={e.nome} value={e.nome}>{e.nome}</option>)
	//     : areaSelected !== ''
	//     ? sncRepList
	//         .sort((a, b) => a.nome.localeCompare(b.nome))
	//         .map((e) => <option key={e.nome} value={e.nome}>{e.nome}</option>)
	//     : [];

	return (
		<div className="main">
			<div className="main-sub">
				{/* {isVisibleTerm && <button style={{marginLeft: '20%', width: '5%'}}>Fechar Termo</button> }
        {isVisibleTerm && <iframe  style={{marginTop: '2%', marginLeft: '20%', position: 'absolute', width: '60%', height: '80%', index:999}} src={linkTermo} title="Termo "></iframe>} */}

				{/* <header className="header">
          <image className="header-logo">
            <img src={logo} alt="logo_sandoz" width="100" height="40" />
          </image>        
        </header> */}

				{/* <div className="logo-ini-cad">
          <img src={logoIni} alt="logo_sandoz" height="50%" />
        </div> */}
				<div className="main-body" style={{ width: "100%" }}>
					<p style={{ fontSize: 27, fontWeight: "bold" }}>¡Inscríbete aquí!!</p>
					<div className="form-wrapper" style={{ width: "100%" }}>
						<form onSubmit={formik.handleSubmit} style={{ width: "100vw" }}>
							<div>
								<div
									id="div_189658"
									className="form-group"
									style={{ textAlign: "left" }}
								>
									<label id="label_189658">Nombre completo (*)</label>
									<input
										id="nome"
										enabled
										name="nome"
										style={{ fontSize: "12px" }}
										className="form-control Enabled"
										autoComplete="off"
										required
										title
										maxLength={300}
										data-original-title
										aria-required="true"
										onChange={formik.handleChange}
										value={formik.values.nome}
									/>
								</div>
								<div
									id="div_189673"
									className="form-group"
									style={{ textAlign: "left" }}
								>
									<label id="label_189673">Correo electrónico (*)</label>
									<input
										id="email"
										enabled
										name="email"
										style={{ fontSize: "12px" }}
										className="form-control Enabled"
										autoComplete="off"
										required
										title
										maxLength={200}
										data-original-title
										aria-required="true"
										onChange={formik.handleChange}
										value={formik.values.email}
									/>
								</div>
								<div className="formik-message-error">
									{formik.touched.email && formik.errors.email}
								</div>
								<div
									id="div_189659"
									className="form-group"
									style={{ textAlign: "left" }}
								>
									<label id="label_189659">Teléfono (*)</label>
									<input
										id="celular"
										enabled
										name="celular"
										style={{ fontSize: "12px" }}
										className="form-control Enabled"
										autoComplete="off"
										data-mascara-value="(00)00000-0000"
										required
										title
										maxLength={30}
										data-original-title
										aria-required="true"
										onChange={formik.handleChange}
										value={formik.values.celular}
									/>
								</div>
								<div
									id="div_189671"
									className="form-group"
									style={{ textAlign: "left" }}
								>
									<label id="label_189671">Ciudad (*)</label>
									<input
										id="cidade"
										enabled
										name="cidade"
										style={{ fontSize: "12px" }}
										className="form-control Enabled"
										autoComplete="off"
										required
										title
										maxLength={15}
										data-original-title
										aria-required="true"
										onChange={formik.handleChange}
										value={formik.values.cidade}
									/>
								</div>
								<div
									id="div_189671"
									className="form-group"
									style={{ textAlign: "left" }}
								>
									<label id="label_189671">País (*)</label>
									<input
										id="pais"
										enabled
										name="pais"
										style={{ fontSize: "12px" }}
										className="form-control Enabled"
										autoComplete="off"
										required
										title
										maxLength={15}
										data-original-title
										aria-required="true"
										onChange={formik.handleChange}
										value={formik.values.pais}
									/>
								</div>
								<div
									id="div_189671"
									className="form-group"
									style={{ textAlign: "left" }}
								>
									<label id="label_189671">Código postal (*)</label>
									<input
										id="cep"
										enabled
										name="cep"
										style={{ fontSize: "12px" }}
										className="form-control Enabled"
										autoComplete="off"
										required
										data-mascara-value="00000-000"
										title
										maxLength={15}
										data-original-title
										aria-required="true"
										onChange={formik.handleChange}
										value={formik.values.cep}
									/>
								</div>
								<div
									id="div_189666"
									className="form-group"
									style={{ textAlign: "left" }}
								>
									<label id="label_189666">Formación (*)</label>
									<select
										required
										id="formacao"
										name="formacao"
										style={{ height: "35px", fontSize: "12px" }}
										className="form-control cssNotReadOnly valid"
										value={formik.values.formacao}
										onChange={(e) => {
											formik.setFieldValue("formacao", e.target.value);
										}}
									>
										<option value="">elije una respuesta</option>
										<option value="Estudiante de maestría">
											Estudiante de maestría
										</option>
										<option value="Recién graduado">Recién graduado</option>
										<option value="Graduado con especialidad diferente a implantología">
											Graduado con especialidad diferente a implantología
										</option>
										<option value="Estudiante de implantología">
											Estudiante de implantología
										</option>
										<option value="Implantólogo">Implantólogo</option>
									</select>
								</div>
								<div
									id="div_189666"
									className="form-group"
									style={{ textAlign: "left" }}
								>
									<label id="label_189666">¿Eres cliente Straumann? (*)</label>
									<select
										required
										id="client"
										name="client"
										style={{ height: "35px", fontSize: "12px" }}
										className="form-control cssNotReadOnly valid"
										value={formik.values.client}
										onChange={(e) => {
											formik.setFieldValue("client", e.target.value);
										}}
									>
										<option value="">elije una respuesta</option>
										<option value="Sí">Sí</option>
										<option value="No">No</option>
									</select>
								</div>
								<div
									id="div_189666"
									className="form-group"
									style={{ textAlign: "left" }}
								>
									<label id="label_189666">¿Deseas ser contactado? (*)</label>
									<select
										required
										id="contact"
										name="contact"
										style={{ height: "35px", fontSize: "12px" }}
										className="form-control cssNotReadOnly valid"
										value={formik.values.contact}
										onChange={(e) => {
											formik.setFieldValue("contact", e.target.value);
										}}
									>
										<option value="">elije una respuesta</option>
										<option value="Sí">Sí</option>
										<option value="No">No</option>
									</select>
								</div>
								<div
									id="div_189666"
									className="form-group"
									style={{ textAlign: "left" }}
								>
									<label id="label_189671">Términos de aceptación (*)</label>
									<div
										disabled
										className="form-control Enabled"
										row={8}
										style={{
											width: "100%",
											height: "auto",
											wordWrap: "break-word",
											display: " inline-block",
											overflow: "hidden",
											height: expanded ? "auto" : 50,
											transition: "0.5s",
										}}
									>
										"Acepto y soy consciente de que los datos proporcionados por
										mí en este formulario serán recopilados, almacenados y
										utilizados por JJGC Indústria e Comércio de Materiais
										Dentários SA (Neodent) e Yller Biomateriais SA, con el fin
										de enviarlos al correo electrónico registrado y / o WhatsApp
										sobre cursos, conferencias y eventos, así como información
										sobre promociones y lanzamientos de nuevos productos que
										involucran las marcas comercializadas en Brasil por las
										empresas Neodent e Yller. Para obtener más información y
										acceder a nuestra política de privacidad de datos, haga clic
										aquí:
										<a href="https://www.straumann.com/neodent/br/pt/profissional/aviso-de-privácia.html">
											{" "}
											POLÍTICA DE PRIVACIDAD
										</a>
									</div>
									<div
										style={{
											top: -10,
											position: "relative",
											flexDirection: "row",
											justifyContent: "center",
											width: 100,
											display: "flex",
											height: 19,
											width: "100vw",
										}}
									>
										<span
											onClick={() => setExpanded(!expanded)}
											style={{
												color: "#555555!important",
												background: "#eeeeee",
												position: "absolute",
												padding: 3,
												paddingRight: 10,
												paddingLeft: 10,
												borderRadius: 17,
												cursor: "pointer",
											}}
										>
											{expanded ? "Muestra menos" : "Leer más"}
										</span>
									</div>
									<FormGroup aria-label="position" row>
										<FormControlLabel
											control={
												<Checkbox
													size="small"
													id="termoPrivacidade"
													color="primary"
													required
													name="termoPrivacidade"
													value={formik.values.termoPrivacidade}
													onChange={(values) =>
														formik.setFieldValue(
															"termoPrivacidade",
															!formik.values.termoPrivacidade
														)
													}
												/>
											}
											label={
												<div className="term-input-wrapper">
													<p style={{ margin: 0 }}>
														Declaro que he leído y acepto las condiciones
														mencionadas.
													</p>
												</div>
											}
										/>
									</FormGroup>
								</div>
							</div>
							<div className="form-group div_button_register">
								<button
									type="submit"
									style={{
										width: 300,
										margin: "0 auto",
										fontSize: 15,
										backgroundColor: "#222425",
										color: "white",
									}}
									name="btnSalvarLandingPage"
									value="LandingPage"
									id="btnSalvar"
									onclick="AdicionarSubmitButtonFormulario()"
									className="btn btn-secondary button_register"
									data-original-title
									title
								>
									Inscribirse
								</button>
								{percent > 0 && (
									<div
										id="imgLoadingResponsive"
										className="hidden"
										style={{ marginTop: "20px" }}
									>
										<div className="progress" style={{ height: "25px" }}>
											<div
												style={{ height: "35px", width: `${percent}%` }}
												className="progress-bar progress-bar-striped progress-bar-animated"
												id="progressBar"
												role="progressbar"
												aria-valuenow={0}
												aria-valuemin={0}
												aria-valuemax={100}
											>
												<p id="nmrPerc" />
											</div>
										</div>
									</div>
								)}
							</div>
						</form>
						{/* <div>
              <p>Adicione o evento em seu Calendário</p>
            <a style={styles.btn_container} onClick={()=> downloadICS(`BEGIN:VCALENDAR
VERSION:2.0
PRODID:-//Our Company//NONSGML v1.0//EN
BEGIN:VEVENT
UID:me@google.com
DTSTAMP:20120315T170000Z
ATTENDEE;CN=My Self ;RSVP=TRUE:MAILTO:me@gmail.com
ORGANIZER;CN=Me:MAILTO::me@gmail.com
DTSTART:20210426T183000
DTEND:20210427T214000
LOCATION:
SUMMARY:SIMPÓSIO NEODENT
END:VEVENT
END:VCALENDAR`)}>
              <i class="fa fa-apple" aria-hidden="true" style={{fontSize: 22}}></i>
              <span style={{marginLeft: 10}}>ICALENDAR</span>
              </a>
              <a style={styles.btn_container} onClick={()=> downloadICS(`BEGIN:VCALENDAR
VERSION:2.0
PRODID:-//Our Company//NONSGML v1.0//EN
BEGIN:VEVENT
UID:me@google.com
DTSTAMP:20120315T170000Z
ATTENDEE;CN=My Self ;RSVP=TRUE:MAILTO:me@gmail.com
ORGANIZER;CN=Me:MAILTO::me@gmail.com
DTSTART:20210426T183000
DTEND:20210427T214000
LOCATION:
SUMMARY:SIMPÓSIO NEODENT
END:VEVENT
END:VCALENDAR`)}>
              <i class="fa fa-windows" aria-hidden="true" style={{fontSize: 22}}></i>
              <span style={{marginLeft: 10}}>OUTLOOK</span>
              </a>
              <a style={styles.btn_container} onClick={()=>openICS()}>
              <i class="fa fa-google" aria-hidden="true" style={{fontSize: 22}}></i>
                <span style={{marginLeft: 10}}>GOOGLE</span>
              </a>
            </div> */}
					</div>
					<p>
						Si ya se encuentra inscrito al evento, de{" "}
						<a
							style={{ fontWeight: "bold" }}
							target="_blank"
							href={`https://hall.inteegra.com.br/${eventId}`}
						>
							clic aquí
						</a>{" "}
						para ingresar.
					</p>
				</div>
			</div>
		</div>
	);
};

const styles = {
	btn_container: {
		backgroundColor: "#222425",
		color: "rgb(255, 255, 255)",
		height: "auto",
		boxShadow:
			"0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
		padding: "6px 16px",
		fontSize: "0.875rem",
		minWidth: "64px",
		boxSizing: "border-box",
		transition:
			"background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
		fontWeight: 500,
		lineHeight: 1.75,
		borderRadius: 4,
		letterSpacing: "0.02857em",
		textTransform: "uppercase",
		border: 0,
		cursor: "pointer",
		margin: 5,
		display: "inline-flex",
		outline: 0,
		fontSize: 12,
	},
};

export default E1904;
