import React, { useState, useEffect } from "react";
import "../../../../styles/main.css";

import { cardioRepList, sncRepList } from "../../../static";
import { useFormik, Field } from "formik";
import * as yup from "yup";

import { addNewUserApi } from "../../../../services/userService";
import { sendEmailApi, getHtmlString } from "../../../../services/emailService";
import { getTokenUserApi } from "../../../../services/tokenService";
import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";

const eventId = 76517;

const validationSchema = yup.object({
  email: yup
    .string("")
    .email("Se requiere un email válido.")
    .required("Email requerido."),
});

const E76517 = () => {
  const [percent, setPercent] = useState(0);
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      nickname: "",
      cellphone: "",
      country: "",
      city: "",
      cep: "",
      isClient: false,
      receiveContact: true,
      aceptTerms: true,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      let arrayObj = [];
      let objValue = {};
      let _email = "";
      Object.keys(values).forEach((e) => {
        if (e === "name") {
          objValue = { id: 209998, value: values[e] };
        }
        if (e === "email") {
          _email = values[e].trim();
          objValue = { id: 209999, value: values[e].trim() };
        }
        if (e === "nickname") {
          objValue = { id: 210869, value: values[e] };
        }
        if (e === "cellphone") {
          objValue = { id: 210986, value: values[e] };
        }
        if (e === "country") {
          objValue = { id: 210987, value: values[e] };
        }
        if (e === "city") {
          objValue = { id: 210988, value: values[e] };
        }
        if (e === "cep") {
          objValue = { id: 210989, value: values[e] };
        }
        if (e === "isClient") {
          objValue = { id: 210991, value: values[e] };
        }
        if (e === "receiveContact") {
          objValue = { id: 210994, value: values[e] };
        }
        if (e === "aceptTerms") {
          objValue = { id: 210995, value: values[e] };
        }
        arrayObj.push(objValue);
      });
      if (loading) return;
      setLoading(true);

      const grupoId = cardioRepList
        .filter((e) => e.nome === formik.values.representante)
        .map((e) => e.grupoId)[0];
      setPercent(10);
      // Verifica se o usuário já possui cadastro
      var _token = null;
      await getTokenUserApi(_email, eventId)
        .then((res) => {
          _token = res;
        })
        .catch((err) => {
          console.log(err);
        });
      setPercent(20);
      if (_token) {
        window.parent.postMessage("onSubmit_success", "*");
        setLoading(false);
      } else {
        addNewUserApi(arrayObj, false, grupoId, eventId)
          .then(async (res) => {
            resetForm({ values: "" });
            var _textHtml = `
                <img src="https://s3.us-east-1.amazonaws.com/bucket.aws.public/hall_ed/inscri.png" width="100%"/>
              `;
            setPercent(50);
            await sendEmailApi(res.email, "E-SYMPOSIUM - STRAUMANN", _textHtml)
              .then(() => {
                console.log("Email enviado com sucesso");
                window.parent.postMessage("onSubmit_success", "*");
                setLoading(false);
              })
              .catch(() => {
                console.log("Erro ao enviar email");
                setLoading(false);
              });
            setPercent(80);
            /*
      await getTokenUserApi(_email, eventId)
        .then((res) => {
        _token = res;
        window.location.href = `https://hall.inteegra.com.br/72784?token=${_token}`;
        })
        .catch((err) => {
        console.log(err);
        }); */
          })
          .catch((err) => {
            setLoading(false);
            alert(
              "Error, no se puede registrar: ",
              err.toString()
            );
          });
      }
    },
  });

  // useEffect(() => {
  //   formik.setFieldValue('representante', '');
  // }, [areaSelected]);
  const lista = cardioRepList
    .sort((a, b) => a.nome.localeCompare(b.nome))
    .map((e) => (
      <option key={e.nome} value={e.nome}>
        {e.nome}
      </option>
    ));
  // const lista =
  //   areaSelected === 'cardiologia'
  //     ? cardioRepList
  //         .sort((a, b) => a.nome.localeCompare(b.nome))
  //         .map((e) => <option key={e.nome} value={e.nome}>{e.nome}</option>)
  //     : areaSelected !== ''
  //     ? sncRepList
  //         .sort((a, b) => a.nome.localeCompare(b.nome))
  //         .map((e) => <option key={e.nome} value={e.nome}>{e.nome}</option>)
  //     : [];

  return (
    <div className="main">
      <div className="main-sub">
        {/* {isVisibleTerm && <button style={{marginLeft: '20%', width: '5%'}}>Fechar Termo</button> }
        {isVisibleTerm && <iframe  style={{marginTop: '2%', marginLeft: '20%', position: 'absolute', width: '60%', height: '80%', index:999}} src={linkTermo} title="Termo "></iframe>} */}

        {/* <header className="header">
          <image className="header-logo">
            <img src={logo} alt="logo_sandoz" width="100" height="40" />
          </image>        
        </header> */}

        {/* <div className="logo-ini-cad">
          <img src={logoIni} alt="logo_sandoz" height="50%" />
        </div> */}
        <div className="main-body" style={{ width: "100%" }}>
          <p style={{ fontSize: 27, fontWeight: "bold" }}>¡Registrate aquí!</p>
          <div className="form-wrapper" style={{ width: "100%" }}>
            <form onSubmit={formik.handleSubmit} style={{ width: "100vw" }}>
              <div>
                <div
                  id="div_209998"
                  className="form-group"
                  style={{ textAlign: "left" }}
                >
                  <label id="label_209998">Nombre (*)</label>
                  <input
                    id="name"
                    enabled
                    name="name"
                    style={{ fontSize: "12px" }}
                    className="form-control Enabled"
                    autoComplete="off"
                    required
                    title
                    maxLength={300}
                    data-original-title
                    aria-required="true"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                  />
                </div>
                <div
                  id="div_209999"
                  className="form-group"
                  style={{ textAlign: "left" }}
                >
                  <label id="label_209999">Email (*)</label>
                  <input
                    id="email"
                    enabled
                    name="email"
                    style={{ fontSize: "12px" }}
                    className="form-control Enabled"
                    autoComplete="off"
                    required
                    title
                    maxLength={200}
                    data-original-title
                    aria-required="true"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                  />
                </div>
                <div className="formik-message-error">
                  {formik.touched.email && formik.errors.email}
                </div>
                <div
                  id="div_210869"
                  className="form-group"
                  style={{ textAlign: "left" }}
                >
                  <label id="label_210869">Apellido (*)</label>
                  <input
                    id="nickname"
                    enabled
                    name="nickname"
                    style={{ fontSize: "12px" }}
                    className="form-control Enabled"
                    autoComplete="off"
                    required
                    title
                    maxLength={300}
                    data-original-title
                    aria-required="true"
                    onChange={formik.handleChange}
                    value={formik.values.nickname}
                  />
                </div>
                <div
                  id="div_210986"
                  className="form-group"
                  style={{ textAlign: "left" }}
                >
                  <label id="label_210986">Celular (*)</label>
                  <input
                    id="cellphone"
                    enabled
                    name="cellphone"
                    style={{ fontSize: "12px" }}
                    className="form-control Enabled"
                    autoComplete="off"
                    data-mascara-value="(00)00000-0000"
                    required
                    title
                    maxLength={30}
                    data-original-title
                    aria-required="true"
                    onChange={formik.handleChange}
                    value={formik.values.cellphone}
                  />
                </div>
                <div
                  id="div_210987"
                  className="form-group"
                  style={{ textAlign: "left" }}
                >
                  <label id="label_210987">País (*)</label>
                  <input
                    id="country"
                    enabled
                    name="country"
                    style={{ fontSize: "12px" }}
                    className="form-control Enabled"
                    autoComplete="off"
                    required
                    title
                    maxLength={300}
                    data-original-title
                    aria-required="true"
                    onChange={formik.handleChange}
                    value={formik.values.country}
                  />
                </div>
                <div
                  id="div_210988"
                  className="form-group"
                  style={{ textAlign: "left" }}
                >
                  <label id="label_210988">Ciudad (*)</label>
                  <input
                    id="city"
                    enabled
                    name="city"
                    style={{ fontSize: "12px" }}
                    className="form-control Enabled"
                    autoComplete="off"
                    required
                    title
                    maxLength={300}
                    data-original-title
                    aria-required="true"
                    onChange={formik.handleChange}
                    value={formik.values.city}
                  />
                </div>
                <div
                  id="div_210989"
                  className="form-group"
                  style={{ textAlign: "left" }}
                >
                  <label id="label_210989">Código Postal (*)</label>
                  <input
                    id="cep"
                    enabled
                    name="cep"
                    style={{ fontSize: "12px" }}
                    className="form-control Enabled"
                    autoComplete="off"
                    required
                    data-mascara-value="00000-000"
                    title
                    maxLength={15}
                    data-original-title
                    aria-required="true"
                    onChange={formik.handleChange}
                    value={formik.values.cep}
                  />
                </div>
                <div
                  id="div_210991"
                  className="form-group"
                  style={{ textAlign: "left" }}
                >
                  <label id="label_210991">Eres cliente Straumann? (*)</label>
                  <select
                    required
                    id="isClient"
                    name="isClient"
                    style={{ height: "35px", fontSize: "12px" }}
                    className="form-control cssNotReadOnly valid"
                    value={formik.values.isClient}
                    onChange={(e) => {
                      formik.setFieldValue("isClient", e.target.value);
                    }}
                  >
                    <option value="Si">Si</option>
                    <option value="No">No</option>
                  </select>
                </div>
                <div
                  id="div_210994"
                  className="form-group"
                  style={{ textAlign: "left" }}
                >
                  <label id="label_210994">Desea ser contactado por nuestro equipo? (*)</label>
                  <select
                    required
                    id="receiveContact"
                    name="receiveContact"
                    style={{ height: "35px", fontSize: "12px" }}
                    className="form-control cssNotReadOnly valid"
                    value={formik.values.receiveContact}
                    onChange={(e) => {
                      formik.setFieldValue("receiveContact", e.target.value);
                    }}
                  >
                    <option value="Si">Si</option>
                    <option value="No">No</option>
                  </select>
                </div>

                <div
                  id="div_210995"
                  className="form-group"
                  style={{ textAlign: "left" }}
                >
                  <label id="label_210995">Aceptación de términos y condiciones (*)</label>
                  <div
                    disabled
                    className="form-control Enabled"
                    row={8}
                    style={{
                      width: "100%",
                      height: "auto",
                      wordWrap: "break-word",
                      display: " inline-block",
                      overflow: "hidden",
                      height: expanded ? "auto" : 50,
                      transition: "0.5s",
                    }}
                  >
                    "Acepto que mi registro y el uso de este sitio
                    web se hará de acuerdo con los Términos y Condiciones
                    y la Política de Privacidad del Grupo Straumann.
                    Como beneficio de unirme a Biomaterials Day LATAM,
                    deseo recibir noticias y ofertas privilegiadas sobre
                    promociones de productos, servicios y eventos del
                    Grupo Straumann". Para más información y acceso a nuestra
                    política de privacidad de datos, haga clic aquí:
                    <a href="https://www.straumann.com/group/en/discover/privacy/es/es.html">
                      {" "}
                      POLÍTICA DE PRIVACIDAD
                    </a>
                  </div>
                  <div
                    style={{
                      top: -10,
                      position: "relative",
                      flexDirection: "row",
                      justifyContent: "center",
                      width: 100,
                      display: "flex",
                      height: 19,
                      width: "100vw",
                    }}
                  >
                    <span
                      onClick={() => setExpanded(!expanded)}
                      style={{
                        color: "#555555!important",
                        background: "#eeeeee",
                        position: "absolute",
                        padding: 3,
                        paddingRight: 10,
                        paddingLeft: 10,
                        borderRadius: 17,
                        cursor: "pointer",
                      }}
                    >
                      {expanded ? "Muestra Menos" : "Ver Más"}
                    </span>
                  </div>
                  <FormGroup aria-label="position" row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          id="aceptTerms"
                          color="primary"
                          required
                          name="aceptTerms"
                          value={formik.values.aceptTerms}
                          onChange={(values) =>
                            formik.setFieldValue(
                              "aceptTerms",
                              !formik.values.aceptTerms
                            )
                          }
                        />
                      }
                      label={
                        <div className="term-input-wrapper">
                          <p style={{ margin: 0 }}>
                            Declaro que he leído y acepto los términos anteriores.
                          </p>
                        </div>
                      }
                    />
                  </FormGroup>
                </div>
              </div>
              <div className="form-group div_button_register">
                <button
                  type="submit"
                  style={{
                    width: 300,
                    margin: "0 auto",
                    fontSize: 15,
                    backgroundColor: "#222425",
                    color: "white",
                  }}
                  name="btnSalvarLandingPage"
                  value="LandingPage"
                  id="btnSalvar"
                  onclick="AdicionarSubmitButtonFormulario()"
                  className="btn btn-secondary button_register"
                  data-original-title
                  title
                >
                  ¡REGISTRATE AHORA!
                </button>
                {percent > 0 && (
                  <div
                    id="imgLoadingResponsive"
                    className="hidden"
                    style={{ marginTop: "20px" }}
                  >
                    <div className="progress" style={{ height: "25px" }}>
                      <div
                        style={{ height: "35px", width: `${percent}%` }}
                        className="progress-bar progress-bar-striped progress-bar-animated"
                        id="progressBar"
                        role="progressbar"
                        aria-valuenow={0}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      >
                        <p id="nmrPerc" />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </form>
            {/* <div>
              <p>Adicione o evento em seu Calendário</p>
            <a style={styles.btn_container} onClick={()=> downloadICS(`BEGIN:VCALENDAR
VERSION:2.0
PRODID:-//Our Company//NONSGML v1.0//EN
BEGIN:VEVENT
UID:me@google.com
DTSTAMP:20120315T170000Z
ATTENDEE;CN=My Self ;RSVP=TRUE:MAILTO:me@gmail.com
ORGANIZER;CN=Me:MAILTO::me@gmail.com
DTSTART:20210426T183000
DTEND:20210427T214000
LOCATION:
SUMMARY:SIMPÓSIO NEODENT
END:VEVENT
END:VCALENDAR`)}>
              <i class="fa fa-apple" aria-hidden="true" style={{fontSize: 22}}></i>
              <span style={{marginLeft: 10}}>ICALENDAR</span>
              </a>
              <a style={styles.btn_container} onClick={()=> downloadICS(`BEGIN:VCALENDAR
VERSION:2.0
PRODID:-//Our Company//NONSGML v1.0//EN
BEGIN:VEVENT
UID:me@google.com
DTSTAMP:20120315T170000Z
ATTENDEE;CN=My Self ;RSVP=TRUE:MAILTO:me@gmail.com
ORGANIZER;CN=Me:MAILTO::me@gmail.com
DTSTART:20210426T183000
DTEND:20210427T214000
LOCATION:
SUMMARY:SIMPÓSIO NEODENT
END:VEVENT
END:VCALENDAR`)}>
              <i class="fa fa-windows" aria-hidden="true" style={{fontSize: 22}}></i>
              <span style={{marginLeft: 10}}>OUTLOOK</span>
              </a>
              <a style={styles.btn_container} onClick={()=>openICS()}>
              <i class="fa fa-google" aria-hidden="true" style={{fontSize: 22}}></i>
                <span style={{marginLeft: 10}}>GOOGLE</span>
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  btn_container: {
    backgroundColor: "#222425",
    color: "rgb(255, 255, 255)",
    height: "auto",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    padding: "6px 16px",
    fontSize: "0.875rem",
    minWidth: "64px",
    boxSizing: "border-box",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    fontWeight: 500,
    lineHeight: 1.75,
    borderRadius: 4,
    letterSpacing: "0.02857em",
    textTransform: "uppercase",
    border: 0,
    cursor: "pointer",
    margin: 5,
    display: "inline-flex",
    outline: 0,
    fontSize: 12,
  },
};

export default E76517;
