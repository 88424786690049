import React, { useState, useEffect } from 'react';
import '../../../../styles/main.css';

import { cardioRepList, sncRepList } from '../../../static';
import { useFormik, Field } from 'formik';
import * as yup from 'yup';

import { addNewUserApi } from '../../../../services/userService';
import { sendEmailApi, getHtmlString } from '../../../../services/emailService';
import { getTokenUserApi } from '../../../../services/tokenService';
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';

const eventId = 73664

const validationSchema = yup.object({
  email: yup
    .string('')
    .email('Digite um email válido.')
    .required('Email é requerido.')
});

const E1904 = () => {
  const [percent, setPercent] = useState(0);
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const formik = useFormik({
    initialValues: {
      nome: '',
      email: '',
      cpf: '',
      celular: '',
      cep: '',
      formacao: '',
      termoPrivacidade: false
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      let arrayObj = [];
      let objValue = {};
      let _email = '';
      Object.keys(values).forEach((e) => {
        if (e === 'nome') {
          objValue = { id: 194599, value: values[e] };
        }
        if (e === 'email') {
          _email = values[e].trim();
          objValue = { id: 194600, value: values[e].trim() };
        }
        if (e === 'cpf') {
          objValue = { id: 194672, value: values[e] };
        }
        if (e === 'celular') {
          objValue = { id: 194673, value: values[e] };
        }
        if (e === 'cep') {
          objValue = { id: 194677, value: values[e] };
        }
        if(e === 'formacao') {
          objValue = { id: 194678, value: values[e] };
        }
        if(e === 'cidade') {
          objValue = { id: 197087, value: values[e] };
        }
        if(e === 'pais') {
          objValue = { id: 196648, value: values[e] };
        }
        if(e === 'termoPrivacidade') {
          objValue = { id: 194676, value: values[e] };
        }
        arrayObj.push(objValue);
      });
      
      if(loading) return;
        setLoading(true)

      const grupoId = cardioRepList
        .filter((e) => e.nome === formik.values.representante)
        .map((e) => e.grupoId)[0];
      setPercent(10);
      // Verifica se o usuário já possui cadastro
      var _token = null;
      await getTokenUserApi(_email, eventId)
        .then((res) => {
          _token = res;
        })
        .catch((err) => {
          console.log(err);
        });
        setPercent(20);
      if (_token) {
        window.location.href = `/E0806/success`;
      } else {
        addNewUserApi(arrayObj, false, grupoId, eventId)
          .then(async (res) => {
            resetForm({ values: '' });
            var _textHtml = `
                <img src="https://s3.amazonaws.com/bucket.aws.public/2021/215997.PNG" width="100%"/>
              `
            setPercent(50);
             await sendEmailApi(res.email, 'e-Symposium Straumann', _textHtml)
              .then(() => {
                console.log("Email enviado com sucesso");
                window.location.href = `/E0806/success`;
              })
              .catch(() => {
                console.log("Erro ao enviar email");
              });
              setPercent(80);
            /*
            await getTokenUserApi(_email, eventId)
              .then((res) => {
                _token = res;
                window.location.href = `https://hall.inteegra.com.br/73664?token=${_token}`;
              })
              .catch((err) => {
                console.log(err);
              }); */
          })
          .catch((err) => {
            alert(
              'Erro, não foi possível realizar o cadastro: ',
              err.toString()
            );
          });
      }
    },
  });

  // useEffect(() => {
  //   formik.setFieldValue('representante', '');
  // }, [areaSelected]);
  const lista = cardioRepList
    .sort((a, b) => a.nome.localeCompare(b.nome))
    .map((e) => (
      <option key={e.nome} value={e.nome}>
        {e.nome}
      </option>
    ));
  // const lista =
  //   areaSelected === 'cardiologia'
  //     ? cardioRepList
  //         .sort((a, b) => a.nome.localeCompare(b.nome))
  //         .map((e) => <option key={e.nome} value={e.nome}>{e.nome}</option>)
  //     : areaSelected !== ''
  //     ? sncRepList
  //         .sort((a, b) => a.nome.localeCompare(b.nome))
  //         .map((e) => <option key={e.nome} value={e.nome}>{e.nome}</option>)
  //     : [];

  return (
    <div className="main">
      <div className="main-sub">
        {/* {isVisibleTerm && <button style={{marginLeft: '20%', width: '5%'}}>Fechar Termo</button> }
        {isVisibleTerm && <iframe  style={{marginTop: '2%', marginLeft: '20%', position: 'absolute', width: '60%', height: '80%', index:999}} src={linkTermo} title="Termo "></iframe>} */}

        {/* <header className="header">
          <image className="header-logo">
            <img src={logo} alt="logo_sandoz" width="100" height="40" />
          </image>        
        </header> */}

        {/* <div className="logo-ini-cad">
          <img src={logoIni} alt="logo_sandoz" height="50%" />
        </div> */}
        <div className="main-body"  style={{ width: "100%"}}>
          <p style={{fontSize: 27, fontWeight: "bold"}}>Inscreva-se aqui!</p>
          <div className="form-wrapper" style={{ width: "100%"}}>
            <form onSubmit={formik.handleSubmit} style={{width: "100vw"}}>
            <div>
              <div 
                id="div_189658" className="form-group" style={{textAlign: "left"}}>
                <label id="label_189658">Nome Completo (*)</label> 
                <input 
                    id="nome" 
                    enabled 
                    name="nome" 
                    style={{fontSize: '12px'}} 
                    className="form-control Enabled" 
                    autoComplete="off" 
                    required 
                    title 
                    maxLength={300} 
                    data-original-title 
                    aria-required="true" 
                    onChange={formik.handleChange}
                    value={formik.values.nome}
                    />
              </div>
              <div id="div_189673" className="form-group" style={{textAlign: "left"}}>
                <label id="label_189673">Email (*)</label> 
                <input 
                  id="email" 
                  enabled 
                  name="email" 
                  style={{fontSize: '12px'}} 
                  className="form-control Enabled" 
                  autoComplete="off" 
                  required 
                  title 
                  maxLength={200} 
                  data-original-title 
                  aria-required="true" 
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  />
              </div>
              <div className="formik-message-error">
                  {formik.touched.email && formik.errors.email}
                </div>
              <div id="div_189674" className="form-group" style={{textAlign: "left"}}>
                <label id="label_189674">CPF (*)</label> 
                <input 
                  id="cpf" 
                  enabled 
                  name="cpf" 
                  data-mascara-value="000.000.000-00"
                  maxlength="30"
                  style={{fontSize: '12px'}} 
                  className="form-control Enabled" 
                  autoComplete="off" 
                  required 
                  title 
                  maxLength={200} 
                  data-original-title 
                  aria-required="true" 
                  onChange={formik.handleChange}
                  value={formik.values.cpf}
                  />
              </div>
              <div id="div_189659" className="form-group" style={{textAlign: "left"}}>
                <label id="label_189659">Celular (*)</label> 
                <input 
                  id="celular" 
                  enabled 
                  name="celular" 
                  style={{fontSize: '12px'}} 
                  className="form-control Enabled" 
                  autoComplete="off" 
                  data-mascara-value="(00)00000-0000"
                  required 
                  title 
                  maxLength={30} 
                  data-original-title 
                  aria-required="true" 
                  onChange={formik.handleChange}
                  value={formik.values.celular}
                  />
              </div>
              <div id="div_189671" className="form-group" style={{textAlign: "left"}}>
                <label id="label_189671">Cidade (*)</label> 
                <input 
                  id="cidade" 
                  enabled 
                  name="cidade" 
                  style={{fontSize: '12px'}} 
                  className="form-control Enabled" 
                  autoComplete="off" 
                  required 
                  title maxLength={15} 
                  data-original-title 
                  aria-required="true" 
                  onChange={formik.handleChange}
                  value={formik.values.cidade}
                />
              </div> 
              <div id="div_189671" className="form-group" style={{textAlign: "left"}}>
                <label id="label_189671">País (*)</label> 
                <input 
                  id="pais" 
                  enabled 
                  name="pais" 
                  style={{fontSize: '12px'}} 
                  className="form-control Enabled" 
                  autoComplete="off" 
                  required 
                  title maxLength={15} 
                  data-original-title 
                  aria-required="true" 
                  onChange={formik.handleChange}
                  value={formik.values.pais}
                />
              </div> 
              <div id="div_189671" className="form-group" style={{textAlign: "left"}}>
                <label id="label_189671">CEP (*)</label> 
                <input 
                  id="cep" 
                  enabled 
                  name="cep" 
                  style={{fontSize: '12px'}} 
                  className="form-control Enabled" 
                  autoComplete="off" 
                  required 
                  data-mascara-value="00000-000" 
                  title maxLength={15} 
                  data-original-title 
                  aria-required="true" 
                  onChange={formik.handleChange}
                  value={formik.values.cep}
                />
              </div>
              <div id="div_189666" className="form-group" style={{textAlign: "left"}}>
                <label id="label_189666">Formação (*)</label> 
                <select
                    required
                    id="formacao"
                    name="formacao"
                    style={{height: '35px', fontSize: '12px'}} 
                    className="form-control cssNotReadOnly valid"   
                    value={formik.values.formacao}
                    onChange={(e) => {
                      formik.setFieldValue('formacao', e.target.value);
                    }}
                  >
                    <option value="Selecione">
                      Selecione
                    </option>
                    <option value="Graduando">
                      Graduando
                    </option>
                    <option value="Recém Graduado">
                      Recém Graduado
                    </option>
                    <option value="Graduado em Especializações sem ser Implantodontia">
                      Graduado em Especializações sem ser Implantodontia
                    </option>
                    <option value="Estudantes de Implantodontia">
                      Estudantes de Implantodontia
                    </option>
                    <option value="Implantodontista">
                      Implantodontista
                    </option>
                  </select>
              </div>
              <div id="div_189666" className="form-group" style={{textAlign: "left"}}>
              <label id="label_189671">Termo de aceite (*)</label> 
                <div 
                  disabled
                  className="form-control Enabled"
                  row={8}
                  style={{
                      width: "100%",
                      height: "auto",
                      wordWrap: "break-word",
                      display:" inline-block",
                      overflow: 'hidden',
                      height: expanded ? 'auto' : 50,
                      transition: '0.5s'
                }}>
                    "Aceito e estou ciente de que os dados fornecidos por mim neste formulário serão coletados, armazenados e utilizados pela JJGC Indústria e Comércio de Materiais Dentários S.A (Neodent) e Yller Biomateriais S.A, com a finalidade de enviar para o e-mail e/ou WhatsApp cadastrados, materiais de cursos, palestras e eventos, bem como informativos de promoções de produtos e lançamentos de novos produtos que envolvam as marcas comercializadas no Brasil pelas empresas Neodent e Yller. Para maiores informações e acesso à nossa política de privacidade de dados, clique aqui:
                    <a href="https://www.straumann.com/neodent/br/pt/profissionais/aviso-de-privacidade.html"> POLITICA DE PRIVACIDADE</a>                                        
                </div>
                <div style={{
                      top: -10,
                      position: "relative",
                      flexDirection: 'row',
                      justifyContent: "center",
                      width: 100,
                      display: "flex",
                      height: 19,
                      width: "100vw"
                }}>
                  <span 
                    onClick={()=> setExpanded(!expanded)}
                    style={{
                        color: "#555555!important",
                        background: "#eeeeee",
                        position: "absolute",
                        padding: 3,
                        paddingRight: 10,
                        paddingLeft: 10,
                        borderRadius: 17,
                        cursor: "pointer"
                  }}>{expanded ? "Mostrar Menos" : "Ler mais"}</span>
                </div>
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        id="termoPrivacidade"
                        color="primary"
                        required
                        name="termoPrivacidade"
                        value={formik.values.termoPrivacidade}
                        onChange={(values) =>
                          formik.setFieldValue(
                            'termoPrivacidade',
                            !formik.values.termoPrivacidade
                          )
                        }
                      />
                    }
                    label={
                      <div className="term-input-wrapper">
                        <p style={{ margin: 0 }}>Declaro que li e aceito os termos acima.</p>
                      </div>
                    }
                  />
                </FormGroup>
              </div>
            </div>
            <div className="form-group div_button_register">
              <button 
                type="submit" style={{
                    width: 300,
                    margin: "0 auto",
                    fontSize: 15,
                    backgroundColor: "#222425",
                    color: 'white'
              }} name="btnSalvarLandingPage" value="LandingPage" id="btnSalvar" onclick="AdicionarSubmitButtonFormulario()" className="btn btn-secondary button_register" data-original-title title>Registrar-se</button>
              {percent > 0 && 
                <div id="imgLoadingResponsive" className="hidden" style={{marginTop: '20px'}}>
                  <div className="progress" style={{height: '25px'}}>
                    <div 
                      style={{height: '35px', width: `${percent}%`}} 
                      className="progress-bar progress-bar-striped progress-bar-animated" 
                      id="progressBar" 
                      role="progressbar" 
                      aria-valuenow={0} 
                      aria-valuemin={0} aria-valuemax={100}>
                      <p id="nmrPerc" />
                    </div>
                  </div>
                </div>
              }
            </div>
            </form>
            {/* <div>
              <p>Adicione o evento em seu Calendário</p>
            <a style={styles.btn_container} onClick={()=> downloadICS(`BEGIN:VCALENDAR
VERSION:2.0
PRODID:-//Our Company//NONSGML v1.0//EN
BEGIN:VEVENT
UID:me@google.com
DTSTAMP:20120315T170000Z
ATTENDEE;CN=My Self ;RSVP=TRUE:MAILTO:me@gmail.com
ORGANIZER;CN=Me:MAILTO::me@gmail.com
DTSTART:20210426T183000
DTEND:20210427T214000
LOCATION:
SUMMARY:SIMPÓSIO NEODENT
END:VEVENT
END:VCALENDAR`)}>
              <i class="fa fa-apple" aria-hidden="true" style={{fontSize: 22}}></i>
              <span style={{marginLeft: 10}}>ICALENDAR</span>
              </a>
              <a style={styles.btn_container} onClick={()=> downloadICS(`BEGIN:VCALENDAR
VERSION:2.0
PRODID:-//Our Company//NONSGML v1.0//EN
BEGIN:VEVENT
UID:me@google.com
DTSTAMP:20120315T170000Z
ATTENDEE;CN=My Self ;RSVP=TRUE:MAILTO:me@gmail.com
ORGANIZER;CN=Me:MAILTO::me@gmail.com
DTSTART:20210426T183000
DTEND:20210427T214000
LOCATION:
SUMMARY:SIMPÓSIO NEODENT
END:VEVENT
END:VCALENDAR`)}>
              <i class="fa fa-windows" aria-hidden="true" style={{fontSize: 22}}></i>
              <span style={{marginLeft: 10}}>OUTLOOK</span>
              </a>
              <a style={styles.btn_container} onClick={()=>openICS()}>
              <i class="fa fa-google" aria-hidden="true" style={{fontSize: 22}}></i>
                <span style={{marginLeft: 10}}>GOOGLE</span>
              </a>
            </div> */}
          </div>
          <p>Se você já está inscrito no evento, <a  style={{fontWeight: 'bold'}} target="_blank" href={`https://hall.inteegra.com.br/${eventId}`}>
            clique aqui</a> para acessa-lo.</p>
        </div>
      </div>
    </div>
  );
}

const styles= {
  btn_container: {
    backgroundColor: "#222425",
    color: "rgb(255, 255, 255)",
    height: "auto",
    boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    padding: "6px 16px",
    fontSize: "0.875rem",
    minWidth: "64px",
    boxSizing: "border-box",
    transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    fontWeight: 500,
    lineHeight: 1.75,
    borderRadius: 4,
    letterSpacing: "0.02857em",
    textTransform: "uppercase",
    border: 0,
    cursor: "pointer",
    margin: 5,
    display: "inline-flex",
    outline: 0,
    fontSize: 12
  }
}

export default E1904;
