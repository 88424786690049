import React, { useState, useEffect } from 'react';
import '../../../../styles/main.css';

import { cardioRepList, sncRepList } from '../../../static';
import { useFormik, Field } from 'formik';
import * as yup from 'yup';

import { addNewUserApi } from '../../../../services/userService';
import { sendEmailApi, getHtmlString } from '../../../../services/emailService';
import { getTokenUserApi } from '../../../../services/tokenService';
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';

const eventId = 73284

const validationSchema = yup.object({
  email: yup
    .string('')
    .email('Invalid email address.')
    .required('Email is required.')
});

const E1504 = () => {
  const [percent, setPercent] = useState(0);
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const formik = useFormik({
    initialValues: {
      firstname: '',
      email: '',
      lastname: '',
      country: '',
     /*  city: '',
      other: '',
      birthdate: '',
      gender: '', */
      speciality: '',
      termoPrivacidade: false
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      let arrayObj = [];
      let objValue = {};
      let _email = '';
      Object.keys(values).forEach((e) => {
        if (e === 'firstname') {
          objValue = { id: 192269, value: values[e] };
        }
        if (e === 'email') {
          _email = values[e].trim();
          objValue = { id: 192270, value: values[e].trim() };
        }
        if (e === 'lastname') {          
          objValue = { id: 19227, value: values[e] };
        }
        if (e === 'country') {
          objValue = { id: 192274, value: `${values[e]}` };
        }
        /* if (e === 'city') {
          objValue = { id: 192275, value: values[e] };
        }
        if(e === 'other') {
          objValue = { id: 192277, value: values[e] };
        }
        if(e === 'birthdate') {
          objValue = { id: 192278, value: values[e] };
        }
        if(e === 'gender') {
          objValue = { id: 192279, value: values[e] };
        } */
        if(e === 'speciality') {
          objValue = { id: 192371, value: values[e] };
        }
        if(e === 'termoPrivacidade') {
          objValue = { id: 193094, value: values[e] };
        }
        arrayObj.push(objValue);
      });

      if(loading) return;
      setLoading(true)

      const grupoId = cardioRepList
        .filter((e) => e.nome === formik.values.representante)
        .map((e) => e.grupoId)[0];
      setPercent(10);
      // Verifica se o usuário já possui cadastro
      var _token = null;
      await getTokenUserApi(_email, eventId)
        .then((res) => {
          _token = res;
        })
        .catch((err) => {
          console.log(err);
        });
        setPercent(20);
      if (_token) {
        window.parent.postMessage("onSubmit_success", "*");
      } else {
        addNewUserApi(arrayObj, false, grupoId, eventId)
          .then(async (res) => {
            resetForm({ values: '' });
            var _textHtml = `
                <img src="https://s3.amazonaws.com/bucket.aws.public/2021/218727.PNG" width="100%"/>
              `
            setPercent(50);
             await sendEmailApi(res.email, 'IMMEDIACY FOR ALL', _textHtml)
              .then(() => {
                console.log("Email enviado com sucesso");
                window.parent.postMessage("onSubmit_success", "*");
              })
              .catch(() => {
                console.log("Erro ao enviar email");
              });
              setPercent(80);
            /*
            await getTokenUserApi(_email, eventId)
              .then((res) => {
                _token = res;
                window.location.href = `https://hall.inteegra.com.br/73284?token=${_token}`;
              })
              .catch((err) => {
                console.log(err);
              }); */
          })
          .catch((err) => {
            alert(
              'Erro, não foi possível realizar o cadastro: ',
              err.toString()
            );
          });
      }
    },
  });

  // useEffect(() => {
  //   formik.setFieldValue('representante', '');
  // }, [areaSelected]);
  const lista = cardioRepList
    .sort((a, b) => a.nome.localeCompare(b.nome))
    .map((e) => (
      <option key={e.nome} value={e.nome}>
        {e.nome}
      </option>
    ));
  // const lista =
  //   areaSelected === 'cardiologia'
  //     ? cardioRepList
  //         .sort((a, b) => a.nome.localeCompare(b.nome))
  //         .map((e) => <option key={e.nome} value={e.nome}>{e.nome}</option>)
  //     : areaSelected !== ''
  //     ? sncRepList
  //         .sort((a, b) => a.nome.localeCompare(b.nome))
  //         .map((e) => <option key={e.nome} value={e.nome}>{e.nome}</option>)
  //     : [];

  return (
    <div className="main">
      <div className="main-sub">
        {/* {isVisibleTerm && <button style={{marginLeft: '20%', width: '5%'}}>Fechar Termo</button> }
        {isVisibleTerm && <iframe  style={{marginTop: '2%', marginLeft: '20%', position: 'absolute', width: '60%', height: '80%', index:999}} src={linkTermo} title="Termo "></iframe>} */}

        {/* <header className="header">
          <image className="header-logo">
            <img src={logo} alt="logo_sandoz" width="100" height="40" />
          </image>        
        </header> */}

        {/* <div className="logo-ini-cad">
          <img src={logoIni} alt="logo_sandoz" height="50%" />
        </div> */}
        <div className="main-body"  style={{ width: "100%"}}>
          <p style={{fontSize: 27, fontWeight: "bold"}}>Register here!</p>
          <div className="form-wrapper" style={{ width: "100%"}}>
            <form onSubmit={formik.handleSubmit} style={{width: "100vw"}}>
            <div>
            <div 
                id="div_189658" className="form-group" style={{textAlign: "left"}}>
                <label id="label_189658">First Name (*)</label> 
                <input 
                    id="firstname" 
                    enabled 
                    name="firstname" 
                    style={{fontSize: '12px'}} 
                    className="form-control Enabled" 
                    autoComplete="off" 
                    required 
                    title 
                    maxLength={300} 
                    data-original-title 
                    aria-required="true" 
                    onChange={formik.handleChange}
                    value={formik.values.firstname}
                    />
              </div>
              <div 
                id="div_189658" className="form-group" style={{textAlign: "left"}}>
                <label id="label_189658">Last Name (*)</label> 
                <input 
                    id="lastname" 
                    enabled 
                    name="lastname" 
                    style={{fontSize: '12px'}} 
                    className="form-control Enabled" 
                    autoComplete="off" 
                    required 
                    title 
                    maxLength={300} 
                    data-original-title 
                    aria-required="true" 
                    onChange={formik.handleChange}
                    value={formik.values.lastname}
                    />
              </div>
              <div id="div_189673" className="form-group" style={{textAlign: "left"}}>
                <label id="label_189673">Email (*)</label> 
                <input 
                  id="email" 
                  enabled 
                  name="email" 
                  style={{fontSize: '12px'}} 
                  className="form-control Enabled" 
                  autoComplete="off" 
                  required 
                  title 
                  maxLength={200} 
                  data-original-title 
                  aria-required="true" 
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  />
              </div>
              <div className="formik-message-error">
                  {formik.touched.email && formik.errors.email}
              </div>
              <div id="div_189674" className="form-group" style={{textAlign: "left"}}>
                <label id="label_189674">Country (*)</label> 
                <select
                    required
                    id="country"
                    name="country"
                    style={{height: '35px', fontSize: '12px'}} 
                    className="form-control cssNotReadOnly valid"   
                    value={formik.values.country}
                    onChange={(e) => {
                      formik.setFieldValue('country', e.target.value);
                    }}
                  >
                    <option value="Afghanistan">Afghanistan</option>
                    <option value="Åland Islands">Åland Islands</option>
                    <option value="Albania">Albania</option>
                    <option value="Algeria">Algeria</option>
                    <option value="American Samoa">American Samoa</option>
                    <option value="Andorra">Andorra</option>
                    <option value="Angola">Angola</option>
                    <option value="Anguilla">Anguilla</option>
                    <option value="Antarctica">Antarctica</option>
                    <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                    <option value="Argentina">Argentina</option>
                    <option value="Armenia">Armenia</option>
                    <option value="Aruba">Aruba</option>
                    <option value="Australia">Australia</option>
                    <option value="Austria">Austria</option>
                    <option value="Azerbaijan">Azerbaijan</option>
                    <option value="Bahamas">Bahamas</option>
                    <option value="Bahrain">Bahrain</option>
                    <option value="Bangladesh">Bangladesh</option>
                    <option value="Barbados">Barbados</option>
                    <option value="Belarus">Belarus</option>
                    <option value="Belgium">Belgium</option>
                    <option value="Belize">Belize</option>
                    <option value="Benin">Benin</option>
                    <option value="Bermuda">Bermuda</option>
                    <option value="Bhutan">Bhutan</option>
                    <option value="Bolivia">Bolivia</option>
                    <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                    <option value="Botswana">Botswana</option>
                    <option value="Bouvet Island">Bouvet Island</option>
                    <option value="Brazil">Brazil</option>
                    <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                    <option value="Brunei Darussalam">Brunei Darussalam</option>
                    <option value="Bulgaria">Bulgaria</option>
                    <option value="Burkina Faso">Burkina Faso</option>
                    <option value="Burundi">Burundi</option>
                    <option value="Cambodia">Cambodia</option>
                    <option value="Cameroon">Cameroon</option>
                    <option value="Canada">Canada</option>
                    <option value="Cape Verde">Cape Verde</option>
                    <option value="Cayman Islands">Cayman Islands</option>
                    <option value="Central African Republic">Central African Republic</option>
                    <option value="Chad">Chad</option>
                    <option value="Chile">Chile</option>
                    <option value="China">China</option>
                    <option value="Christmas Island">Christmas Island</option>
                    <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                    <option value="Colombia">Colombia</option>
                    <option value="Comoros">Comoros</option>
                    <option value="Congo">Congo</option>
                    <option value="Congo, The Democratic Republic of The">Congo, The Democratic Republic of The</option>
                    <option value="Cook Islands">Cook Islands</option>
                    <option value="Costa Rica">Costa Rica</option>
                    <option value="Cote D'ivoire">Cote D'ivoire</option>
                    <option value="Croatia">Croatia</option>
                    <option value="Cuba">Cuba</option>
                    <option value="Cyprus">Cyprus</option>
                    <option value="Czech Republic">Czech Republic</option>
                    <option value="Denmark">Denmark</option>
                    <option value="Djibouti">Djibouti</option>
                    <option value="Dominica">Dominica</option>
                    <option value="Dominican Republic">Dominican Republic</option>
                    <option value="Ecuador">Ecuador</option>
                    <option value="Egypt">Egypt</option>
                    <option value="El Salvador">El Salvador</option>
                    <option value="Equatorial Guinea">Equatorial Guinea</option>
                    <option value="Eritrea">Eritrea</option>
                    <option value="Estonia">Estonia</option>
                    <option value="Ethiopia">Ethiopia</option>
                    <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                    <option value="Faroe Islands">Faroe Islands</option>
                    <option value="Fiji">Fiji</option>
                    <option value="Finland">Finland</option>
                    <option value="France">France</option>
                    <option value="French Guiana">French Guiana</option>
                    <option value="French Polynesia">French Polynesia</option>
                    <option value="French Southern Territories">French Southern Territories</option>
                    <option value="Gabon">Gabon</option>
                    <option value="Gambia">Gambia</option>
                    <option value="Georgia">Georgia</option>
                    <option value="Germany">Germany</option>
                    <option value="Ghana">Ghana</option>
                    <option value="Gibraltar">Gibraltar</option>
                    <option value="Greece">Greece</option>
                    <option value="Greenland">Greenland</option>
                    <option value="Grenada">Grenada</option>
                    <option value="Guadeloupe">Guadeloupe</option>
                    <option value="Guam">Guam</option>
                    <option value="Guatemala">Guatemala</option>
                    <option value="Guernsey">Guernsey</option>
                    <option value="Guinea">Guinea</option>
                    <option value="Guinea-bissau">Guinea-bissau</option>
                    <option value="Guyana">Guyana</option>
                    <option value="Haiti">Haiti</option>
                    <option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
                    <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
                    <option value="Honduras">Honduras</option>
                    <option value="Hong Kong">Hong Kong</option>
                    <option value="Hungary">Hungary</option>
                    <option value="Iceland">Iceland</option>
                    <option value="India">India</option>
                    <option value="Indonesia">Indonesia</option>
                    <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
                    <option value="Iraq">Iraq</option>
                    <option value="Ireland">Ireland</option>
                    <option value="Isle of Man">Isle of Man</option>
                    <option value="Israel">Israel</option>
                    <option value="Italy">Italy</option>
                    <option value="Jamaica">Jamaica</option>
                    <option value="Japan">Japan</option>
                    <option value="Jersey">Jersey</option>
                    <option value="Jordan">Jordan</option>
                    <option value="Kazakhstan">Kazakhstan</option>
                    <option value="Kenya">Kenya</option>
                    <option value="Kiribati">Kiribati</option>
                    <option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
                    <option value="Korea, Republic of">Korea, Republic of</option>
                    <option value="Kuwait">Kuwait</option>
                    <option value="Kyrgyzstan">Kyrgyzstan</option>
                    <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
                    <option value="Latvia">Latvia</option>
                    <option value="Lebanon">Lebanon</option>
                    <option value="Lesotho">Lesotho</option>
                    <option value="Liberia">Liberia</option>
                    <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                    <option value="Liechtenstein">Liechtenstein</option>
                    <option value="Lithuania">Lithuania</option>
                    <option value="Luxembourg">Luxembourg</option>
                    <option value="Macao">Macao</option>
                    <option value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former Yugoslav Republic of</option>
                    <option value="Madagascar">Madagascar</option>
                    <option value="Malawi">Malawi</option>
                    <option value="Malaysia">Malaysia</option>
                    <option value="Maldives">Maldives</option>
                    <option value="Mali">Mali</option>
                    <option value="Malta">Malta</option>
                    <option value="Marshall Islands">Marshall Islands</option>
                    <option value="Martinique">Martinique</option>
                    <option value="Mauritania">Mauritania</option>
                    <option value="Mauritius">Mauritius</option>
                    <option value="Mayotte">Mayotte</option>
                    <option value="Mexico">Mexico</option>
                    <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                    <option value="Moldova, Republic of">Moldova, Republic of</option>
                    <option value="Monaco">Monaco</option>
                    <option value="Mongolia">Mongolia</option>
                    <option value="Montenegro">Montenegro</option>
                    <option value="Montserrat">Montserrat</option>
                    <option value="Morocco">Morocco</option>
                    <option value="Mozambique">Mozambique</option>
                    <option value="Myanmar">Myanmar</option>
                    <option value="Namibia">Namibia</option>
                    <option value="Nauru">Nauru</option>
                    <option value="Nepal">Nepal</option>
                    <option value="Netherlands">Netherlands</option>
                    <option value="Netherlands Antilles">Netherlands Antilles</option>
                    <option value="New Caledonia">New Caledonia</option>
                    <option value="New Zealand">New Zealand</option>
                    <option value="Nicaragua">Nicaragua</option>
                    <option value="Niger">Niger</option>
                    <option value="Nigeria">Nigeria</option>
                    <option value="Niue">Niue</option>
                    <option value="Norfolk Island">Norfolk Island</option>
                    <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                    <option value="Norway">Norway</option>
                    <option value="Oman">Oman</option>
                    <option value="Pakistan">Pakistan</option>
                    <option value="Palau">Palau</option>
                    <option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
                    <option value="Panama">Panama</option>
                    <option value="Papua New Guinea">Papua New Guinea</option>
                    <option value="Paraguay">Paraguay</option>
                    <option value="Peru">Peru</option>
                    <option value="Philippines">Philippines</option>
                    <option value="Pitcairn">Pitcairn</option>
                    <option value="Poland">Poland</option>
                    <option value="Portugal">Portugal</option>
                    <option value="Puerto Rico">Puerto Rico</option>
                    <option value="Qatar">Qatar</option>
                    <option value="Reunion">Reunion</option>
                    <option value="Romania">Romania</option>
                    <option value="Russian Federation">Russian Federation</option>
                    <option value="Rwanda">Rwanda</option>
                    <option value="Saint Helena">Saint Helena</option>
                    <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                    <option value="Saint Lucia">Saint Lucia</option>
                    <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                    <option value="Saint Vincent and The Grenadines">Saint Vincent and The Grenadines</option>
                    <option value="Samoa">Samoa</option>
                    <option value="San Marino">San Marino</option>
                    <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                    <option value="Saudi Arabia">Saudi Arabia</option>
                    <option value="Senegal">Senegal</option>
                    <option value="Serbia">Serbia</option>
                    <option value="Seychelles">Seychelles</option>
                    <option value="Sierra Leone">Sierra Leone</option>
                    <option value="Singapore">Singapore</option>
                    <option value="Slovakia">Slovakia</option>
                    <option value="Slovenia">Slovenia</option>
                    <option value="Solomon Islands">Solomon Islands</option>
                    <option value="Somalia">Somalia</option>
                    <option value="South Africa">South Africa</option>
                    <option value="South Georgia and The South Sandwich Islands">South Georgia and The South Sandwich Islands</option>
                    <option value="Spain">Spain</option>
                    <option value="Sri Lanka">Sri Lanka</option>
                    <option value="Sudan">Sudan</option>
                    <option value="Suriname">Suriname</option>
                    <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                    <option value="Swaziland">Swaziland</option>
                    <option value="Sweden">Sweden</option>
                    <option value="Switzerland">Switzerland</option>
                    <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                    <option value="Taiwan, Province of China">Taiwan, Province of China</option>
                    <option value="Tajikistan">Tajikistan</option>
                    <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                    <option value="Thailand">Thailand</option>
                    <option value="Timor-leste">Timor-leste</option>
                    <option value="Togo">Togo</option>
                    <option value="Tokelau">Tokelau</option>
                    <option value="Tonga">Tonga</option>
                    <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                    <option value="Tunisia">Tunisia</option>
                    <option value="Turkey">Turkey</option>
                    <option value="Turkmenistan">Turkmenistan</option>
                    <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                    <option value="Tuvalu">Tuvalu</option>
                    <option value="Uganda">Uganda</option>
                    <option value="Ukraine">Ukraine</option>
                    <option value="United Arab Emirates">United Arab Emirates</option>
                    <option value="United Kingdom">United Kingdom</option>
                    <option value="United States">United States</option>
                    <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                    <option value="Uruguay">Uruguay</option>
                    <option value="Uzbekistan">Uzbekistan</option>
                    <option value="Vanuatu">Vanuatu</option>
                    <option value="Venezuela">Venezuela</option>
                    <option value="Viet Nam">Viet Nam</option>
                    <option value="Virgin Islands, British">Virgin Islands, British</option>
                    <option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
                    <option value="Wallis and Futuna">Wallis and Futuna</option>
                    <option value="Western Sahara">Western Sahara</option>
                    <option value="Yemen">Yemen</option>
                    <option value="Zambia">Zambia</option>
                    <option value="Zimbabwe">Zimbabwe</option>
                  </select>
              </div>
              {/* <div id="div_189659" className="form-group" style={{textAlign: "left"}}>
                <label id="label_189659">City (*)</label> 
                <input 
                  id="city" 
                  enabled 
                  name="city" 
                  style={{fontSize: '12px'}} 
                  className="form-control Enabled" 
                  autoComplete="off" 
                  required 
                  title 
                  maxLength={200} 
                  data-original-title 
                  aria-required="true" 
                  onChange={formik.handleChange}
                  value={formik.values.city}
                  />
              </div>
              <div id="div_189671" className="form-group" style={{textAlign: "left"}}>
                <label id="label_189671">Other (*)</label> 
                <input 
                  id="other" 
                  enabled 
                  name="other" 
                  style={{fontSize: '12px'}} 
                  className="form-control Enabled" 
                  autoComplete="off" 
                  required 
                  data-mascara-value="00000-000" 
                  title maxLength={15} 
                  data-original-title 
                  aria-required="true" 
                  onChange={formik.handleChange}
                  value={formik.values.other}
                />
              </div>
              <div id="div_189671" className="form-group" style={{textAlign: "left"}}>
                <label id="label_189671">Birth Date (*)</label> 
                <input 
                  type="date"
                  id="birthdate" 
                  enabled 
                  name="birthdate" 
                  style={{fontSize: '12px'}} 
                  className="form-control Enabled" 
                  autoComplete="off" 
                  required 
                  data-mascara-value="00000-000" 
                  title maxLength={15} 
                  data-original-title 
                  aria-required="true" 
                  onChange={formik.handleChange}
                  value={formik.values.birthdate}
                />
              </div>
              <div id="div_189671" className="form-group" style={{textAlign: "left"}}>
                <label id="label_189671">Gender (*)</label> 
                <select
                    required
                    id="gender"
                    name="gender"
                    style={{height: '35px', fontSize: '12px'}} 
                    className="form-control cssNotReadOnly valid"   
                    value={formik.values.gender}
                    onChange={(e) => {
                      formik.setFieldValue('gender', e.target.value);
                    }}
                  >
                    <option value="">
                      Select
                    </option>
                    <option value="Male">
                      Male
                    </option>
                    <option value="Female">
                      Female
                    </option>               
                  </select>
              </div> */}
              <div id="div_189666" className="form-group" style={{textAlign: "left"}}>
                <label id="label_189666">Speciality (*)</label> 
                <select
                    required
                    id="speciality"
                    name="speciality"
                    style={{height: '35px', fontSize: '12px'}} 
                    className="form-control cssNotReadOnly valid"   
                    value={formik.values.speciality}
                    onChange={(e) => {
                      formik.setFieldValue('speciality', e.target.value);
                    }}
                  >
                    <option value="">
                      Select
                    </option>
                    <option value="Dental Assistant">
                      Dental Assistant
                    </option>
                    <option value="Dental Hygienist">
                      Dental Hygienist
                    </option>
                    <option value="Dental Lab Technician">
                      Dental Lab Technician
                    </option>
                    <option value="Dentist">
                      Dentist
                    </option>
                    <option value="Implantologist">
                      Implantologist
                    </option>
                    <option value="Oral Surgeon">
                      Oral Surgeon
                    </option>
                    <option value="Other">
                      Other
                    </option>
                    <option value="Student/ Graduate">
                      Student/ Graduate
                    </option>                    
                  </select>
              </div>
              <div id="div_189666" className="form-group" style={{textAlign: "left"}}>
              <label id="label_189671">Acceptance Term (*)</label> 
                <div 
                  disabled
                  className="form-control Enabled"
                  row={8}
                  style={{
                      width: "100%",
                      height: "auto",
                      wordWrap: "break-word",
                      display:" inline-block",
                      overflow: 'hidden',
                      height: expanded ? 'auto' : 50,
                      transition: '0.5s'
                }}>
                    "I accept and am aware that the data provided by me in this form will be collected, stored and used by JJGC Indústria e Comércio de Materiais Dentários SA (Neodent) and Yller Biomateriais SA, for the purpose of sending for the registered e-mail and / or WhatsApp newsletter about courses, lectures and events, as well as information about promotions and new product launches involving the brands commercialized in Brazil by the companies Neodent and Yller. For more information and access to our data privacy policy, click here:  
                    <a target="_blank" href="https://www.straumann.com/group/en/discover/privacy/com/en.html"> Privacy Policy</a>"
                </div>
                <div style={{
                      top: -10,
                      position: "relative",
                      flexDirection: 'row',
                      justifyContent: "center",
                      width: 100,
                      display: "flex",
                      height: 19,
                      width: "100vw"
                }}>
                  <span 
                    onClick={()=> setExpanded(!expanded)}
                    style={{
                        color: "#555555!important",
                        background: "#eeeeee",
                        position: "absolute",
                        padding: 3,
                        paddingRight: 10,
                        paddingLeft: 10,
                        borderRadius: 17,
                        cursor: "pointer"
                  }}>{expanded ? "Mostrar Menos" : "Ler mais"}</span>
                </div>
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        id="termoPrivacidade"
                        color="primary"
                        required
                        name="termoPrivacidade"
                        value={formik.values.termoPrivacidade}
                        onChange={(values) =>
                          formik.setFieldValue(
                            'termoPrivacidade',
                            !formik.values.termoPrivacidade
                          )
                        }
                      />
                    }
                    label={
                      <div className="term-input-wrapper">
                        <p style={{ margin: 0 }}>I read and accept the above terms.</p>
                      </div>
                    }
                  />
                </FormGroup>
              </div>
            </div>
            <div className="form-group div_button_register">
              <button 
                type="submit" style={{
                    width: 300,
                    margin: "0 auto",
                    fontSize: 15,
                    backgroundColor: "#222425",
                    color: 'white'
              }} name="btnSalvarLandingPage" value="LandingPage" id="btnSalvar" onclick="AdicionarSubmitButtonFormulario()" className="btn btn-secondary button_register" data-original-title title>Register</button>
              {percent > 0 && 
                <div id="imgLoadingResponsive" className="hidden" style={{marginTop: '20px'}}>
                  <div className="progress" style={{height: '25px'}}>
                    <div 
                      style={{height: '35px', width: `${percent}%`}} 
                      className="progress-bar progress-bar-striped progress-bar-animated" 
                      id="progressBar" 
                      role="progressbar" 
                      aria-valuenow={0} 
                      aria-valuemin={0} aria-valuemax={100}>
                      <p id="nmrPerc" />
                    </div>
                  </div>
                </div>
              }
            </div>
            </form>
            {/* <div>
              <p>Adicione o evento em seu Calendário</p>
            <a style={styles.btn_container} onClick={()=> downloadICS(`BEGIN:VCALENDAR
VERSION:2.0
PRODID:-//Our Company//NONSGML v1.0//EN
BEGIN:VEVENT
UID:me@google.com
DTSTAMP:20120315T170000Z
ATTENDEE;CN=My Self ;RSVP=TRUE:MAILTO:me@gmail.com
ORGANIZER;CN=Me:MAILTO::me@gmail.com
DTSTART:20210426T183000
DTEND:20210427T214000
LOCATION:
SUMMARY:SIMPÓSIO NEODENT
END:VEVENT
END:VCALENDAR`)}>
              <i class="fa fa-apple" aria-hidden="true" style={{fontSize: 22}}></i>
              <span style={{marginLeft: 10}}>ICALENDAR</span>
              </a>
              <a style={styles.btn_container} onClick={()=> downloadICS(`BEGIN:VCALENDAR
VERSION:2.0
PRODID:-//Our Company//NONSGML v1.0//EN
BEGIN:VEVENT
UID:me@google.com
DTSTAMP:20120315T170000Z
ATTENDEE;CN=My Self ;RSVP=TRUE:MAILTO:me@gmail.com
ORGANIZER;CN=Me:MAILTO::me@gmail.com
DTSTART:20210426T183000
DTEND:20210427T214000
LOCATION:
SUMMARY:SIMPÓSIO NEODENT
END:VEVENT
END:VCALENDAR`)}>
              <i class="fa fa-windows" aria-hidden="true" style={{fontSize: 22}}></i>
              <span style={{marginLeft: 10}}>OUTLOOK</span>
              </a>
              <a style={styles.btn_container} onClick={()=>openICS()}>
              <i class="fa fa-google" aria-hidden="true" style={{fontSize: 22}}></i>
                <span style={{marginLeft: 10}}>GOOGLE</span>
              </a>
            </div> */}
          </div>
          <p>If you are already registered, <a  style={{fontWeight: 'bold'}} target="_blank" href={`https://hall.inteegra.com.br/${eventId}`}>
            click here</a> to access.</p>
        </div>
      </div>
    </div>
  );
}

const styles= {
  btn_container: {
    backgroundColor: "#222425",
    color: "rgb(255, 255, 255)",
    height: "auto",
    boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    padding: "6px 16px",
    fontSize: "0.875rem",
    minWidth: "64px",
    boxSizing: "border-box",
    transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    fontWeight: 500,
    lineHeight: 1.75,
    borderRadius: 4,
    letterSpacing: "0.02857em",
    textTransform: "uppercase",
    border: 0,
    cursor: "pointer",
    margin: 5,
    display: "inline-flex",
    outline: 0,
    fontSize: 12
  }
}

export default E1504;
