import React, { useState, useEffect } from 'react';
import '../../../../styles/main.css';
import logo from '../../../../assets/img/IMAGEM001.png';
import btnEntrar from '../../../../assets/img/btn_entrar.png';
import logoIni from '../../../../assets/img/IMAGEM001.png';

import logoFooter from '../../../../assets/img/IMAGEM002.png';
import { cardioRepList, sncRepList } from '../../../static';
import { useFormik, Field } from 'formik';
import * as yup from 'yup';

import { addNewUserApi } from '../../../../services/userService';
import { sendEmailApi, getHtmlString } from '../../../../services/emailService';
import { getTokenUserApi } from '../../../../services/tokenService';
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';

const validationSchema = yup.object({
  areaInteresse: yup.string('').required('Area de interesse é requerida'),
  representante: yup.string('').required('Rerpresentante é requerido'),
  nome: yup.string('').trim().required('Nome completo é requerido'),
  crm: yup.string('').trim().required('CRM é requerido'),
  email: yup
    .string('')
    .email('Digite um email válido')
    .required('Email é requerido'),
  termoPrivacidade: yup
    .bool()
    .oneOf([true], 'Termo requerido'),
  informacoes: yup
    .bool()
    .oneOf([true], 'Termo requerido'),
});

function S1304() {
  
  return (
    <div className="main">
      <div className="main-sub">
        <div className="main-body"  style={{ width: "100%"}}>
          <div className="form-wrapper" style={{ width: "100%"}}>
            <div style={{
              borderRadius: 10,
              padding: 40,
              paddingTop:0,
              fontSize: 18,
              fontFamily: 'sans-serif',
              textAlign: "initial"
            }}>
              <img src="https://s3.us-east-1.amazonaws.com/bucket.aws.public/2020/Emkt-Inscric%CC%A7a%CC%83o%20%281%29.jpg" width="100%"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default S1304;
