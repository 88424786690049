import axios from "axios";

export async function sendEmailApi(emailTo, subject, htmlContent) {
    var method = "https://api-externa.inteegra.com.br/email/api/emails";
    const token = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJsb2dpbiI6Ik9iS1RnYWxWaEUzWnUrYzJKeHZQV2J6Z2RZb0ZDMUdEIiwiY29udHJhY3RDb2RlIjoicmJmK3BQamJONG89IiwiYWdlbmN5Q29kZSI6IkwreUgweThwOFlnPSIsInVzZXJJZCI6IjZmMCtCd3J2OWJVPSIsInByb2ZpbGUiOiJSZVZ4ekF0YkY3RT0iLCJqdGkiOiI5MTQwOTQ3OS0xY2MwLTRjMjUtYTk1Zi00MGI3MDdhNTQwZTEiLCJleHAiOjE2MTQ2MzYwMjMsImlzcyI6IkludGVlZ3JhIiwiYXVkIjoiYXBpLWNsaWVudCJ9.EcQWea_Z0rQFnShUCgcyuNXS42dcxrStlE3Dz7d3JPY`

    const _params = {
        to: [emailTo.trim()],
        from: "naoresponder@inteegra.com.br",
        subject: subject,
        htmlContent: htmlContent
    }

    return new Promise((resolve, reject) => {
        const options = {
            url: method,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "bearer " + token,
            },
            data: _params,
        };
        axios(options)
            .then((res) => {
                resolve(true);
            })
            .catch((err) => {
                console.log("Erro sendEmailApi", err, options);
                reject(err);
            });
    });
}

export function getHtmlString(linkCadastro='cadastro') {
    return `<img src="https://s3.us-east-1.amazonaws.com/bucket.aws.public/hall_desenvolvimento/cc.png" width="100%"/>`
}
